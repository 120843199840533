import React from 'react'
import {EuiTitle, EuiSpacer} from '@elastic/eui'

import ReactSearch from '../../components/Assistants/Review_Wall/react-search-input'

import SEOHelmet from '../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Produkttests',
  description: 'Faktische Produkttests für alle INSTAR IP Kameras von Medien, Blogger und Influencer weltweit. Praktische Produktvideos und Testaufnahmen vorhanden.',
  image: '/images/Search/QI_SearchThumb_SetupWizard.png',
  twitter: '/images/Search/QI_SearchThumb_SetupWizard.webp',
  locationEN: '/en/Assistants/Review_Wall/',
  locationDE: '/de/Assistants/Review_Wall/',
  locationFR: '/fr/Assistants/Review_Wall/'
}



const ReviewWall = (props) => (
  <React.Fragment>

    <SEOHelmet
      title={seodata.title}
      description={seodata.description}
      image={seodata.image}
      location={props.location} />

    <BreadCrumbs
        locationBC={props.location}
        locationEN={props.location.pathname}
        locationDE='/de/Assistants/Review_Wall/'
        locationFR='/fr/Assistants/Review_Wall/'
        crumbLabel="Testimonies" />
        
    <EuiTitle size="m">
        <h2>Reviews</h2>
    </EuiTitle>
    <EuiSpacer size="l"/>
    <ReactSearch />
  </React.Fragment>
)

export default ReviewWall


// export default (props) => (
//   <React.Fragment>
//     <BreadCrumbs location={props.location} crumbLabel="Testimonies" />
//     <EuiTitle size="m">
//         <h2>Reviews</h2>
//     </EuiTitle>
//     <EuiSpacer size="l"/>
//     <ReactSearch />
//   </React.Fragment>
// )