import React, {Component, Fragment} from 'react'

import { EuiFlexGrid, EuiFlexItem, EuiCard, EuiTitle, EuiText, EuiTextColor, EuiSpacer } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'
import SearchInput, {createFilter} from 'react-search-input'

import data from './react-search-input-data'
import './react-search-input.css'

const KEYS_TO_FILTERS = ['orga.platform', 'review.title', 'orga.company', 'id']


class ReviewSearch extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
    this.searchUpdated = this.searchUpdated.bind(this)
  }
  
  render () {
    const filteredData = data.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))

    return (
      <React.Fragment>
        <SearchInput className='search-input euiFieldSearch euiFieldSearch--fullWidth' onChange={this.searchUpdated} fuzzy sortResults />
        <EuiSpacer size="xl" />
        <EuiFlexGrid gutterSize="m" columns={3}>
              {filteredData.map(article => {
                return (
                    <EuiFlexItem style={{ maxWidth: 345 }} key={article.id}>
                        <OutboundLink href={article.review.link} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', textDecoration: 'none' }}>
                          <EuiCard
                            textAlign="left"
                            image={
                              <div>
                                <img
                                  src={article.review.img}
                                  alt={article.review.title}
                                />
                              </div>
                            }
                            footer={
                              <Fragment>
                                <EuiTitle size="xs">
                                  <h4>{article.orga.company}</h4>
                                </EuiTitle>
                                <EuiText>
                                  <p><EuiTextColor color="subdued">{article.review.title}</EuiTextColor></p>
                                </EuiText>
                              </Fragment>
                            }
                            betaBadgeLabel={article.orga.platform}
                            betaBadgeTooltipContent={article.review.title}
                          />
                      </OutboundLink>
                    </EuiFlexItem>
                    )
                  })}
                </EuiFlexGrid>
      </React.Fragment>
    )
  }

  searchUpdated (term) {
    this.setState({searchTerm: term})
  }
}

export default ReviewSearch