let reviews = []

reviews = [{
  id: 'robo1',
  orga: {
    platform: 'Youtube',
    company: 'Roboternetz - Technik & Elektronik'
  },
  review:{
      title: 'Instar IN-8003 Full HD PoE Test – In- und Outdoor Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-8003.webp',
      link: 'https://youtu.be/l3EF_JgdGQg'
    }
}, {
  id: 'm1molter1',
  orga: {
    platform: 'Youtube',
    company: 'M1Molter - Der Heimwerker'
  },
  review: {
      title: 'IP Kamera IN-8003 Full HD PoE / installieren und einrichten - Anleitung',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-8003_2.webp',
      link: 'https://youtu.be/Ac1trrZhu9o'
    }
}, {
  id: 'eddyd1',
  orga: {
    platform: 'Youtube',
    company: 'EddyDs SmartHome'
  },
  review: {
      title: 'Instar IN-8015 + MQTT = 100% ioBroker Kompatibel Vis Visualisierung Überwachungskamera Tutorial',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-8015-3.webp',
      link: 'https://youtu.be/6N0FATzh1BU'
    }
}, {
  id: 'iDomiX1',
  orga: {
    platform: 'Youtube',
    company: 'iDomiX'
  },
  review: {
      title: 'IP-Kamera Tag/Nacht Wärmesensor PAN/TILT Instar IN-8015 | iDomiX',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-8015-2.webp',
      link: 'https://youtu.be/2t7Y7I6l6A0'
    }
}, {
  id: 'm1molter2',
  orga: {
    platform: 'Youtube',
    company: 'M1Molter - Der Heimwerker'
  },
  review: {
      title: 'INStar IN-8015 / Vorstellung und Installation der Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-8015.webp',
      link: 'https://youtu.be/uMhUIP6Z4CM'
    }
}, {
  id: 'm1molter3',
  orga: {
    platform: 'Youtube',
    company: 'M1Molter - Der Heimwerker'
  },
  review: {
      title: 'INSTAR IN-9008 / Vorstellung und Installation der Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008.webp',
      link: 'https://youtu.be/KM0PoxrLUj0'
    }
}, {
  id: 'iDomiX2',
  orga: {
    platform: 'Youtube',
    company: 'iDomiX'
  },
  review: {
      title: 'Keine Fehlalarme! IP-Kamera Outdoor mit Wärmesensor im Review | iDomiX',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008-2.webp',
      link: 'https://youtu.be/Qli2lYba_x8'
    }
}, {
  id: 'automatisierung1',
  orga: {
    platform: 'Youtube',
    company: 'haus-automatisierung.com'
  },
  review: {
      title: 'INSTAR PoE-Kamera - Ersteinrichtung und Features | haus-automatisierung.com [4K]',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008-3.webp',
      link: 'https://youtu.be/gLiKgvkEtU0'
    }
}, {
  id: 'smart1',
  orga: {
    platform: 'Youtube',
    company: 'Smart-Home24.eu'
  },
  review: {
      title: 'INSTAR IN 9008 FullHD - Anleitung & ioBroker Verknüpfung',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008-4.webp',
      link: 'https://youtu.be/YbtanLvPB4Q'
    }
}, {
  id: 'verdrahtet1',
  orga: {
    platform: 'Youtube',
    company: 'verdrahtet.info'
  },
  review: {
      title: 'Instar Kameras via MQTT mit dem ioBroker verbinden - so gehts!',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008-5.webp',
      link: 'https://youtu.be/-eVbBkIpcxE'
    }
}, {
  id: 'smart2',
  orga: {
    platform: 'Youtube',
    company: 'Smart-Home24.eu'
  },
  review: {
      title: 'INSTAR IN 9008 FullHD - MQTT Anleitung & ioBroker Verknüpfung',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008-6.webp',
      link: 'https://youtu.be/ZdflcGXjLeo'
    }
}, {
  id: 'automatisierung2',
  orga: {
    platform: 'Youtube',
    company: 'haus-automatisierung.com'
  },
  review: {
      title: 'INSTAR-Kameras mit MQTT-Support im ioBroker | haus-automatisierung.com',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9008-7.webp',
      link: 'https://youtu.be/AtyzOPuaPmg'
    }
}, {
  id: 'm1molter4',
  orga: {
    platform: 'Youtube',
    company: 'M1Molter - Der Heimwerker'
  },
  review: {
      title: 'Instar IP Kamera IN 9020 Full HD / Installation und Einrichtung',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020.webp',
      link: 'https://youtu.be/rVgi_sWGQs4'
    }
}, {
  id: 'verdrahtet2',
  orga: {
    platform: 'Youtube',
    company: 'verdrahtet.info'
  },
  review: {
      title: 'IP-Kamera Stream auf FritzFon anzeigen | verdrahtet.info',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020-8.webp',
      link: 'https://youtu.be/iOe_ToP0WEA'
    }
}, {
  id: 'm1molter5',
  orga: {
    platform: 'Youtube',
    company: 'M1Molter - Der Heimwerker'
  },
  review: {
      title: 'Instar IP Kamera IN 9020 Full HD / Installation und Einrichtung',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020-5.webp',
      link: 'https://youtu.be/rVgi_sWGQs4'
    }
}, {
  id: 'verdrahtet3',
  orga: {
    platform: 'Youtube',
    company: 'verdrahtet.info'
  },
  review: {
      title: 'Ach Du dickes Ei - Instar IN-9020 FHD Kameras im Kurztest! | verdrahtet.info [4K]',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020-2.webp',
      link: 'https://youtu.be/rx_kkiAxg6o'
    }
}, {
  id: 'mega1',
  orga: {
    platform: 'Youtube',
    company: 'MEGA-Testberichte'
  },
  review: {
      title: 'INSTAR IN-9020 Full HD im Test - Überwachungskamera mit Motor - 4x optischem Zoom',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020-3.webp',
      link: 'https://youtu.be/3RPdYb6HIlo'
    }
}, {
  id: 'perlitschke1',
  orga: {
    platform: 'Youtube',
    company: 'Michael Perlitschke'
  },
  review: {
      title: 'INSTAR IN-9020 Full HD Outdoor Cam Review; PIR ; Pan&Tilt; 4xZoom; WLAN (Features, Setup & Footage)',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020-7.webp',
      link: 'https://youtu.be/p94vu-QRWp8'
    }
}, {
  id: 'robo2',
  orga: {
    platform: 'Youtube',
    company: 'Roboternetz - Technik & Elektronik'
  },
  review: {
      title: 'Instar IN-9020 Full HD Test und Vergleich der Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/YouTube-logo-9020-4.webp',
      link: 'https://youtu.be/wjRV2ttOttI'
    }
}, {
  id: 'android1',
  orga: {
    platform: 'Blog',
    company: 'Android User'
  },
  review: {
      title: 'Im Test: Instar IN-8003 Full HD PoE IP Kamera für Innen- und Aussen',
      img: '/en/images/Assistants/Review_Wall/android-user-8003.webp',
      link: 'https://www.android-user.de/im-test-instar-in-8003-full-hd-poe-ip-kamera-fuer-innen-und-aussen/'
    }
}, {
  id: 'android2',
  orga: {
    platform: 'Blog',
    company: 'Android User'
  },
  review: {
      title: 'Im Test: INSTAR Innenkamera IN-8015 Full HD',
      img: '/en/images/Assistants/Review_Wall/android-user-8015.webp',
      link: 'https://www.android-user.de/im-test-instar-innenkamera-in-8015-full-hd'
    }
}, {
  id: 'android3',
  orga: {
    platform: 'Blog',
    company: 'Android User'
  },
  review: {
      title: 'Im Test: INSTAR Außenkamera IN-9008 FULL HD',
      img: '/en/images/Assistants/Review_Wall/android-user-9008.webp',
      link: 'https://www.android-user.de/im-test-instar-aussenkamera-in-9008-full-hd/'
    }
}, {
  id: 'android4',
  orga: {
    platform: 'Blog',
    company: 'Android User'
  },
  review: {
      title: 'Im Test: INSTAR IN-9020 Full HD Außenkamera',
      img: '/en/images/Assistants/Review_Wall/android-user-9020.webp',
      link: 'https://www.android-user.de/im-test-instar-in-9020-full-hd-aussenkamera/'
    }
}, {
  id: 'android5',
  orga: {
    platform: 'Blog',
    company: 'Android User'
  },
  review: {
      title: 'Im Test: INSTAR IN-LAN 500p Powerline-Adapter',
      img: '/en/images/Assistants/Review_Wall/android-user-in-lan.webp',
      link: 'https://www.android-user.de/im-test-instar-in-lan-500p-powerline-adapter/'
    }
}, {
  id: 'appproject1',
  orga: {
    platform: 'Blog',
    company: 'App Project'
  },
  review: {
      title: 'Instar IN-9008 Full HD Installation und Web-Einrichtung der Kamera mit Homematic',
      img: '/en/images/Assistants/Review_Wall/appproject-9008.webp',
      link: 'https://appproject.de/Hausautomatisierung/Instar9008/Instar_9008_Full_HD.html'
    }
}, {
  id: 'appproject2',
  orga: {
    platform: 'Blog',
    company: 'App Project'
  },
  review: {
      title: 'Instar IN-9020 Full HD und Anwendungsbeispiele mit Homematic.',
      img: '/en/images/Assistants/Review_Wall/appproject-9020.webp',
      link: 'https://appproject.de/Hausautomatisierung/Instar9020/Instar_9020_Full_HD.html'
    }
}, {
  id: 'bwgm1',
  orga: {
    platform: 'Blog',
    company: 'Bewegungsmelder Test'
  },
  review: {
      title: 'Instar IN-8015 Full HD unsere absolute Empfehlung',
      img: '/en/images/Assistants/Review_Wall/bewegungsmelder-test24-8015.webp',
      link: 'https://bewegungsmelder-test24.de/instar-kamera/'
    }
}, {
  id: 'bwgm2',
  orga: {
    platform: 'Blog',
    company: 'Bewegungsmelder Test'
  },
  review: {
      title: 'Instar IN-9008 Full HD unsere absolute Empfehlung',
      img: '/en/images/Assistants/Review_Wall/bewegungsmelder-test24-9008.webp',
      link: 'https://bewegungsmelder-test24.de/instar-kamera/'
    }
}, {
  id: 'alarmanlage1',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'Outdoor WLAN Überwachungskamera Test Instar IN-5907 HD',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-5907.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/outdoor-wlan-ueberwachungskamera-test-instar-in-5907-hd/'
    }
}, {
  id: 'alarmanlage2',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'WLAN Kamera Instar IN-6001HD im Test',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-6001.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/videoueberwachung-wlan-kamera-instar-in-6001hd-test/'
    }
}, {
  id: 'alarmanlage3',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'Test Instar IN-6014 HD WLAN Kamera',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-6014.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/test-instar-in-6014-hd-wlan-kamera/'
    }
}, {
  id: 'alarmanlage4',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'Instar IN-8003 Full HD PoE Test – In- und Outdoor Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-8003.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/instar-in-8003-full-hd-poe-test-indoor-outdoor-netzwerkkamera/'
    }
}, {
  id: 'alarmanlage5',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'Instar IN-8015 Full HD Test – Schwenkbare Innenkamera mit PIR',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-8015.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/instar-in-8015-full-hd-test/'
    }
}, {
  id: 'alarmanlage6',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'Wetterfeste Überwachungskamera IN-9008 Full HD im Test',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-9008.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/ueberwachungskamera-instar-in-9008-full-hd-test/'
    }
}, {
  id: 'alarmanlage7',
  orga: {
    platform: 'Blog',
    company: 'Einbruchschutz und Alarmanlagen'
  },
  review: {
      title: 'Instar IN-9020 Full HD Test – Schwenkbare Außenkamera mit Zoom',
      img: '/en/images/Assistants/Review_Wall/einbruchschutz-9020.webp',
      link: 'https://www.einbruchschutz-und-alarmanlagen.de/instar-in-9020-full-hd-test-zoom-ueberwachungskamera/'
    }
}, {
  id: 'GDGTS1',
  orga: {
    platform: 'Blog',
    company: 'GDGTS'
  },
  review: {
      title: 'Instar IN-6014 HD Videoüberwachung zum Einbruchschutz?',
      img: '/en/images/Assistants/Review_Wall/gdgts-6014.webp',
      link: 'https://gdgts.de/in-6014hd/'
    }
}, {
  id: 'GDGTS2',
  orga: {
    platform: 'Blog',
    company: 'GDGTS'
  },
  review: {
      title: 'Die Instar 8015 HD ist das neuste Modell einer Reihe von Indoor Überwachungskameras der Firma Instar.',
      img: '/en/images/Assistants/Review_Wall/gdgts-8015.webp',
      link: 'https://gdgts.de/instar-8015-hd/'
    }
}, {
  id: 'homesmart1',
  orga: {
    platform: 'Blog',
    company: 'Home & Smart'
  },
  review: {
      title: 'Instar IN-5907 Test-Überblick: Ausstattung im Vergleich',
      img: '/en/images/Assistants/Review_Wall/homeandsmart-5907.webp',
      link: 'https://www.homeandsmart.de/instar-in-5907-outdoor-ueberwachungskamera'
    }
}, {
  id: 'homesmart2',
  orga: {
    platform: 'Blog',
    company: 'Home & Smart'
  },
  review: {
      title: 'InStar IN-9008 Full HD IP-Überwachungskamera im Test-Überblick',
      img: '/en/images/Assistants/Review_Wall/homeandsmart-9008.webp',
      link: 'https://www.homeandsmart.de/instar-in-9008-ueberwachungskamera'
    }
}, {
  id: 'iDomiX3',
  orga: {
    platform: 'Blog',
    company: 'iDomiX'
  },
  review: {
      title: 'IP Kamera IN-8015 Full HD - Tag/Nacht mit Wärmesensor PAN/TILT 1920×1080 Full HD',
      img: '/en/images/Assistants/Review_Wall/idomix-8015.webp',
      link: 'https://idomix.de/ip-kamera-tagnacht-mit-waermesensor-pantilt-1920x1080-full-hd'
    }
}, {
  id: 'iDomiX4',
  orga: {
    platform: 'Blog',
    company: 'iDomiX'
  },
  review: {
      title: 'IP Kamera IN-9008 Full HD - Keine Fehlalarme! IP-Kamera Outdoor mit Wärmesensor im Review',
      img: '/en/images/Assistants/Review_Wall/idomix-9008.webp',
      link: 'https://idomix.de/keine-fehlalarme-ip-kamera-outdoor-mit-waermesensor-im-review'
    }
}, {
  id: 'iot1',
  orga: {
    platform: 'Blog',
    company: 'Internet der Dinge'
  },
  review: {
      title: 'INSTAR IN-8001 Full HD Indoor Kamera im Test',
      img: '/en/images/Assistants/Review_Wall/internet-der-dinge-8001.webp',
      link: 'https://internetderdinge.blog/instar-in-8001-full-hd-innenkamera-im-test/'
    }
}, {
  id: 'iot2',
  orga: {
    platform: 'Blog',
    company: 'Internet der Dinge'
  },
  review: {
      title: 'INSTAR IN-9020 Full HD – Die wetterfeste Überwachungskamera im Test',
      img: '/en/images/Assistants/Review_Wall/internet-der-dinge-9020.webp',
      link: 'https://internetderdinge.blog/instar-in-9020-full-hd-die-wetterfeste-ueberwachungskamera-im-test/'
    }
}, {
  id: 'iot3',
  orga: {
    platform: 'Blog',
    company: 'Internet der Dinge'
  },
  review: {
      title: 'Einrichtung einer INSTAR-Kamera mit der QVR-App von QNAP',
      img: '/en/images/Assistants/Review_Wall/internet-der-dinge-9008-qnap.webp',
      link: 'https://internetderdinge.blog/einrichtung-einer-instar-kamera-mit-der-qvr-app-von-qnap/'
    }
}, {
  id: 'iot4',
  orga: {
    platform: 'Blog',
    company: 'Internet der Dinge'
  },
  review: {
      title: 'INSTAR IN-8015 Full HD Überwachungskamera im Test',
      img: '/en/images/Assistants/Review_Wall/internet-der-dinge-8015.jpg',
      link: 'https://internetderdinge.blog/instar-in-8015-full-hd-ueberwachungskamera-im-test/'
    }
}, {
  id: 'kamtest1',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-2905 V2 Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-2905.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-2905-v2/'
    }
}, {
  id: 'kamtest2',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-3011 Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-3011.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-3011/'
    }
}, {
  id: 'kamtest3',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-5905HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-5905.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-5905hd/'
    }
}, {
  id: 'kamtest5',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-5907HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-5907.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-5907hd/'
    }
}, {
  id: 'kamtest6',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-6001HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-6001.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-6001hd/'
    }
}, {
  id: 'kamtest7',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-6012HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-6012.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-6012hd/'
    }
}, {
  id: 'kamtest8',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-6014HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-6014.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-6014hd/'
    }
}, {
  id: 'kamtest9',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-7011HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-7011.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-7011hd/'
    }
}, {
  id: 'kamtest10',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-8015 Full HD Test',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-8015.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-8015-full-hd/'
    }
}, {
  id: 'kamtest11',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-9008 Full HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-9008.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-9008-full-hd/'
    }
}, {
  id: 'kamtest4',
  orga: {
    platform: 'Blog',
    company: 'IP Kamera Test'
  },
  review: {
      title: 'INSTAR IN-5905HD Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-5905.webp',
      link: 'http://www.ip-kamera-test.net/instar-in-5905hd/'
    }
}, {
  id: 'Macwelt1',
  orga: {
    platform: 'Blog',
    company: 'Macwelt'
  },
  review: {
      title: 'Instar IN-8015 HD - Indoorkamera mit Anspruch',
      img: '/en/images/Assistants/Review_Wall/macwelt-8015.webp',
      link: 'https://www.macwelt.de/a/instar-in-8015-hd-indoorkamera-mit-anspruch,3437676'
    }
}, {
  id: 'Macwelt2',
  orga: {
    platform: 'Blog',
    company: 'Macwelt'
  },
  review: {
      title: 'Instar IN-9008: Außenkamera mit starkem Bild und hoher Datensicherheit',
      img: '/en/images/Assistants/Review_Wall/macwelt-9008.webp',
      link: 'https://www.macwelt.de/a/instar-in-9008-aussenkamera-mit-starkem-bild-und-hoher-datensicherheit,3439508'
    }
}, {
  id: 'Macwelt3',
  orga: {
    platform: 'Blog',
    company: 'Macwelt'
  },
  review: {
      title: 'Instar IN-9020 Full HD: Rundum-Außenkamera mit starkem Bild',
      img: '/en/images/Assistants/Review_Wall/macwelt-9020.webp',
      link: 'https://www.macwelt.de/a/instar-in-9020-full-hd-rundum-aussenkamera-mit-starkem-bild,3439518'
    }
}, {
  id: 'mobiFlip1',
  orga: {
    platform: 'Blog',
    company: 'mobiFlip'
  },
  review: {
      title: 'IP-Kameras: Instar IN-8015 Indoor und IN-9008 Outdoor ausprobiert',
      img: '/en/images/Assistants/Review_Wall/mobiflip-8015.webp',
      link: 'https://www.mobiflip.de/ip-kameras-instar-in-8015-indoor-und-in-9008-outdoor-ausprobiert/'
    }
}, {
  id: 'outdoor1',
  orga: {
    platform: 'Blog',
    company: 'Outdoor Test'
  },
  review: {
      title: 'INSTAR Kameras IN-9020 und IN-8015',
      img: '/en/images/Assistants/Review_Wall/outdoortest_9008.webp',
      link: 'https://www.outdoortest.info/instar-kameras-in-9020-und-in-8015/'
    }
}, {
  id: 'outdoor2',
  orga: {
    platform: 'Blog',
    company: 'Outdoor Test'
  },
  review: {
      title: 'INSTAR Kameras IN-9020 und IN-8015',
      img: '/en/images/Assistants/Review_Wall/outdoortest_8015.webp',
      link: 'https://www.outdoortest.info/instar-kameras-in-9020-und-in-8015/'
    }
}, {
  id: 'augsburg1',
  orga: {
    platform: 'Blog',
    company: 'Presse Augsburg'
  },
  review: {
      title: 'IN-8015 und IN-9008 | INSTAR präsentiert neue IP-Kameras mit Full HD zu Top-Preisen',
      img: '/en/images/Assistants/Review_Wall/presse-augsburg-8015.webp',
      link: 'https://presse-augsburg.de/in-8015-und-in-9008-instar-praesentiert-neue-ip-kameras-mit-full-hd-zu-top-preisen/165119/'
    }
}, {
  id: 'augsburg2',
  orga: {
    platform: 'Blog',
    company: 'Presse Augsburg'
  },
  review: {
      title: 'IN-8015 und IN-9008 | INSTAR präsentiert neue IP-Kameras mit Full HD zu Top-Preisen',
      img: '/en/images/Assistants/Review_Wall/presse-augsburg-9008.webp',
      link: 'https://presse-augsburg.de/in-8015-und-in-9008-instar-praesentiert-neue-ip-kameras-mit-full-hd-zu-top-preisen/165119/'
    }
}, {
  id: 'augsburg3',
  orga: {
    platform: 'Blog',
    company: 'Presse Augsburg'
  },
  review: {
      title: 'Das neue IP-Kamera-Flaggschiff ist da | Die INSTAR IN-9020 Full HD im Presse Augsburg-Test',
      img: '/en/images/Assistants/Review_Wall/presse-augsburg-9020.webp',
      link: 'https://presse-augsburg.de/das-neue-ip-kamera-flaggschiff-ist-da-die-instar-in-9020-full-hd-im-presse-augsburg-test/261580/'
    }
}, {
  id: 'sicher1',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-2905 V2 Test – Eine Der Ersten Überwachungskameras',
      img: '/en/images/Assistants/Review_Wall/ip-kamera-test-2905.webp',
      link: 'https://www.sei-sicherer.de/wlan-kamera-instar-2905-v2-test-2016/'
    }
}, {
  id: 'sicher3',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-5905HD Test – Qualitative Outdoorkamera',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-5905.webp',
      link: 'https://www.sei-sicherer.de/outdoorkamera-test-instar-in-5905hd/'
    }
}, {
  id: 'sicher4',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-5907HD Test – Hochwertige Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-5907.webp',
      link: 'https://www.sei-sicherer.de/ip-kamera-instar-in-5907hd-test/'
    }
}, {
  id: 'sicher5',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-6001HD Test – Geeignet Für Den Innenraum',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-6001.webp',
      link: 'https://www.sei-sicherer.de/instar-in-6001hd-test/'
    }
}, {
  id: 'sicher7',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-6014HD Test – Günstige Überwachung',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-6014.webp',
      link: 'https://www.sei-sicherer.de/ip-kamera-instar-in-6014hd-test/'
    }
}, {
  id: 'sicher8',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar In-7011HD Test – Die Domekamera Von Instar',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-7011.webp',
      link: 'https://www.sei-sicherer.de/wlan-kamera-test-instar-7011hd-2016/'
    }
}, {
  id: 'sicher9',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-8015 Test – Großartige Innen-Überwachung',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-8015.webp',
      link: 'https://www.sei-sicherer.de/instar-in-8015-ip-kamera-test/'
    }
}, {
  id: 'sicher10',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-9008 Test – Eine Besondere Überwachungskamera',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-9008.webp',
      link: 'https://www.sei-sicherer.de/instar-in-9008-full-hd-ueberwachungskamera-test/'
    }
}, {
  id: 'sicher11',
  orga: {
    platform: 'Blog',
    company: 'Sei Sicherer'
  },
  review: {
      title: 'Instar IN-9020 Full HD Test – Unser Testsieger 2020!',
      img: '/en/images/Assistants/Review_Wall/sei-sicherer-9020.webp',
      link: 'https://www.sei-sicherer.de/ueberwachungskamera-test/instar/in-9020-fullhd/'
    }
}, {
  id: 'siio1',
  orga: {
    platform: 'Blog',
    company: 'siio.de'
  },
  review: {
      title: 'IN-9008 Full HD - homee und Instar Kameras: smartes Duo für mehr Sicherheit',
      img: '/en/images/Assistants/Review_Wall/siio-de-9008.webp',
      link: 'https://www.siio.de/homee-und-instar-kameras-smartes-duo-fuer-mehr-sicherheit/'
    }
}, {
  id: 'one1',
  orga: {
    platform: 'Blog',
    company: 'Smarthome One'
  },
  review: {
      title: 'INSTAR IN-8015 Full HD im Test',
      img: '/en/images/Assistants/Review_Wall/smart-home-8015.webp',
      link: 'https://www.smart-home.one/instar-in-8015-full-hd-im-test-20181808'
    }
}, {
  id: 'one2',
  orga: {
    platform: 'Blog',
    company: 'Smarthome One'
  },
  review: {
      title: 'INSTAR IN-8003 Full HD PoE im Test',
      img: '/en/images/Assistants/Review_Wall/smart-home-8003.webp',
      link: 'https://www.smart-home.one/instar-in-8003-full-hd-poe-im-test-20192745'
    }
}, {
  id: 'one3',
  orga: {
    platform: 'Blog',
    company: 'Smarthome One'
  },
  review: {
      title: 'INSTAR IN-9008 Full HD im Test',
      img: '/en/images/Assistants/Review_Wall/smart-home-9008.webp',
      link: 'https://www.smart-home.one/instar-in-9008-full-hd-im-test-20181918'
    }
}, {
  id: 'one4',
  orga: {
    platform: 'Blog',
    company: 'Smarthome One'
  },
  review: {
      title: 'INSTAR IN-9020 Full HD im Test',
      img: '/en/images/Assistants/Review_Wall/smart-home-9020.webp',
      link: 'https://www.smart-home.one/instar-in-9020-full-hd-im-test-20192658'
    }
}, {
  id: 'scar',
  orga: {
    platform: 'Blog',
    company: 'Peter Scargill'
  },
  review: {
      title: 'Instar IN-9008 Full HD MQTT Wired/WIFI IP Camera',
      img: '/en/images/Assistants/Review_Wall/tech-scargill-9008.webp',
      link: 'https://tech.scargill.net/instar-in-9008-full-hd-mqtt-ip-camera/'
    }
}, {
  id: 'stage1',
  orga: {
    platform: 'Blog',
    company: 'Tech Stage'
  },
  review: {
      title: 'Instar IN-8015: schwenkbare IP-Cam mit Full HD im Test',
      img: '/en/images/Assistants/Review_Wall/techstage-8015.webp',
      link: 'https://www.techstage.de/test/Instar-IN-8015-schwenkbare-IP-Cam-mit-Full-HD-im-Test-3819956.html'
    }
}, {
  id: 'techtest1',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Außen-Überwachungskamera im Test, INSTAR IN-5907HD',
      img: '/en/images/Assistants/Review_Wall/techtest-5907.webp',
      link: 'https://techtest.org/aussen-ueberwachungskamera-im-test-instar-in-5907hd/'
    }
}, {
  id: 'techtest3',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Die INSTAR IN-5907HD PoE Überwachungskamera im Test (Synology Surveillance Station kompatibel)',
      img: '/en/images/Assistants/Review_Wall/techtest-5907-poe.webp',
      link: 'https://techtest.org/die-instar-in-5907hd-poe-ueberwachungskamera-im-test-synology-surveillance-station-kompatibel/'
    }
}, {
  id: 'techtest4',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Steuerbare WLAN IP-Überwachungskamera im Test, INSTAR IN-6014HD',
      img: '/en/images/Assistants/Review_Wall/techtest-6014.webp',
      link: 'https://techtest.org/steuerbare-w-lan-ip-ueberwachungskamera-im-test-instar-in-6014hd/'
    }
}, {
  id: 'techtest5',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Die INSTAR IN-8003 Überwachungskamera im Test',
      img: '/en/images/Assistants/Review_Wall/techtest-8003.webp',
      link: 'https://techtest.org/die-instar-in-8003-ueberwachungskamera-im-test/'
    }
}, {
  id: 'techtest6',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Die INSTAR IN-8015 Überwachungskamera im Test',
      img: '/en/images/Assistants/Review_Wall/techtest-8015.webp',
      link: 'https://techtest.org/die-instar-in-8015-ueberwachungskamera-im-test/'
    }
}, {
  id: 'techtest7',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Die INSTAR IN-9008 im Test, Semi-Professionelle Überwachungskamera für den Heimbedarf!',
      img: '/en/images/Assistants/Review_Wall/techtest-9008.webp',
      link: 'https://techtest.org/die-instar-in-9008-im-test-semi-professionelle-ueberwachungskamera-fuer-den-heimbedarf/'
    }
}, {
  id: 'techtest8',
  orga: {
    platform: 'Blog',
    company: 'Techtest.org'
  },
  review: {
      title: 'Die INSTAR IN-9020 im Test, eine massive Überwachungskamera mit optischem Zoom!',
      img: '/en/images/Assistants/Review_Wall/techtest-9020.webp',
      link: 'https://techtest.org/die-instar-in-9020-im-test-eine-massive-ueberwachungskamera-mit-optischem-zoom/'
    }
}, {
  id: 'wlankam1',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-9020 Full HD Test (Outdoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-9020.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-9020-full-hd-test/'
    }
}, {
  id: 'wlankam2',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-9008 Full HD Test (Outdoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-9008.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-9008-full-hd-test/'
    }
}, {
  id: 'wlankam3',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-8015 Test (Indoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-8015.webp',
      link: 'http://wlankamera24.net/indoor/instar-in-8015-test-indoor/'
    }
}, {
  id: 'wlankam4',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-5905HD Test (Outdoor Kamera)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-5905.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-5905hd-test/'
    }
}, {
  id: 'wlankam5',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-5907HD im Test (Outdoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-5907.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-5907hd-im-test/'
    }
}, {
  id: 'wlankam6',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-6014HD Test (Indoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-6014.webp',
      link: 'http://wlankamera24.net/indoor/instar-in-6014hd-test-indoor/'
    }
}, {
  id: 'wlankam7',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Instar IN-2905 V2 (Outdoor Kamera)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-2905v2.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-2905-v2-test/'
    }
}, {
  id: 'wlankam8',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera'
  },
  review: {
      title: 'Die Instar IN 6001 HD WLAN-Kamera im Test',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera-6001.webp',
      link: 'https://www.wlan-kamera.info/produkt-test/die-instar-6001-hd-wlan-kamera-im-test'
    }
}, {
  id: 'wlankam9',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-9020 Full HD Test (Outdoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-9020.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-9020-full-hd-test/'
    }
}, {
  id: 'wlankam10',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-9008 Full HD Test (Outdoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-9008.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-9008-full-hd-test/'
    }
}, {
  id: 'wlankam11',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-8015 Test (Indoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-8015.webp',
      link: 'http://wlankamera24.net/indoor/instar-in-8015-test-indoor/'
    }
}, {
  id: 'wlankam12',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-5905HD Test (Outdoor Kamera)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-5905.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-5905hd-test/'
    }
}, {
  id: 'wlankam13',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-5907HD im Test (Outdoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-5907.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-5907hd-im-test/'
    }
}, {
  id: 'wlankam14',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-6014HD Test (Indoor)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-6014.webp',
      link: 'http://wlankamera24.net/indoor/instar-in-6014hd-test-indoor/'
    }
}, {
  id: 'wlankam15',
  orga: {
    platform: 'Blog',
    company: 'WLAN Kamera 24'
  },
  review: {
      title: 'Instar IN-2905 V2 (Outdoor Kamera)',
      img: '/en/images/Assistants/Review_Wall/wlan-kamera24-2905v2.webp',
      link: 'http://wlankamera24.net/outdoor/instar-in-2905-v2-test/'
    }
  }, {
    id: 'netzzoom1',
    orga: {
      platform: 'Blog',
      company: 'Netz.Zoom;)'
    },
    review: {
        title: 'Überwachungskamera INSTAR IN-8001 FullHD im Test',
        img: '/de/images/Assistants/Review_Wall/netzzoom-8001.webp',
        link: 'https://www.netzzoom.de/smarthome/7260-review-ueberwachungskamera-instar-in-8001-fullhd-testbericht/'
      }
  }]


  export default reviews